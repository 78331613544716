import {
  IInitAppForPage,
  IPlatformAPI,
  IPlatformServices,
  IWixAPI,
  IAppData,
} from '@wix/native-components-infra/dist/es/src/types/types';
import { initRaven } from '@wix/bookings-adapters-reporting/dist/src/sentry/sentry-adapter';
import { createWidgetController } from '@wix/bookings-widget-viewer/dist/src/platform/create-widget-controller';
import {
  createOfferingListWidgetController,
  createMainPageController,
} from '@wix/bookings-widget/src/index';
import createBookingServicePageController from '@wix/bookings-service-details-widget/src/components/BookingServicePage/controller';
import {
  BookingsErrorReporter,
  withBookingsErrorReporter,
} from '@wix/bookings-adapters-reporting/dist/src/error-reporter/error-adapter';
import {
  dailyTimeTableCreateController,
  bookButtonCreateController,
  staffListCreateController,
  DAILY_TIMETABLE_WIDGET_ID,
  BOOK_BUTTON_WIDGET_ID,
  STAFF_LIST_WIDGET_ID,
} from '@wix/bookings-app-builder-controllers';
const BOOKINGS_WIDGET_ID = '14756c3d-f10a-45fc-4df1-808f22aabe80';
const BOOKINGS_OFFERING_LIST_WIDGET_ID = 'cc882051-73c9-41a6-8f90-f6ebc9f10fe1';
const BOOKINGS_LIST_PAGE_ID = '621bc837-5943-4c76-a7ce-a0e38185301f';
const BOOKINGS_SERVICE_DETAILS_PAGE_ID = 'a91a0543-d4bd-4e6b-b315-9410aa27bcde';

const controllerByType = {
  [BOOKINGS_WIDGET_ID]: createWidgetController,
  [BOOKINGS_OFFERING_LIST_WIDGET_ID]: createOfferingListWidgetController,
  [BOOKINGS_LIST_PAGE_ID]: createMainPageController,
  [DAILY_TIMETABLE_WIDGET_ID]: dailyTimeTableCreateController,
  [BOOK_BUTTON_WIDGET_ID]: bookButtonCreateController,
  [STAFF_LIST_WIDGET_ID]: staffListCreateController,
  [BOOKINGS_SERVICE_DETAILS_PAGE_ID]: createBookingServicePageController,
};

let ravenReporter: BookingsErrorReporter = () => null;

export function createControllers(controllerConfigs) {
  //is controller url
  return controllerConfigs.map(async (config) =>
    controllerByType[config.type]
      ? withBookingsErrorReporter(ravenReporter)(
          await (controllerByType[config.type] as any)(config, ravenReporter),
        )
      : undefined,
  );
}

export const initAppForPage: IInitAppForPage = async (
  initParams: IAppData,
  apis: IPlatformAPI,
  namespaces: IWixAPI,
  platformServices: IPlatformServices,
) => {
  ravenReporter = initRaven(platformServices, initParams);
};
